@font-face {
    font-family: "ABC Diatype Unlicensed Trial";
    src: local("ABC Diatype Unlicensed Trial"), url(./assets/ABCDiatype-Regular-Trial.otf) format("opentype"); /* Regular */
}

@font-face {
    font-family: "ABC Diatype Unlicensed Trial";
    font-weight: 300;
    src: local("ABC Diatype Unlicensed Trial"), url(./assets/ABCDiatype-Thin-Trial.otf) format("opentype"); /* Thin */
}

@font-face {
    font-family: "ABC Diatype Unlicensed Trial";
    font-weight: 500;
    src: local("ABC Diatype Unlicensed Trial"), url(./assets/ABCDiatype-Medium-Trial.otf) format("opentype"); /* Medium */
}

:root {
    --background-light: #f8f9fb;
    --background-light-gradient: transparent;
    --background-dark: #151619;
    --scrollbar-light: rgba(0, 0, 0, 0.2);
    --scrollbar-hover-light: rgba(0, 0, 0, 0.5);
    --scrollbar-dark: rgba(255, 255, 255, 0.2);
    --scrollbar-hover-dark: rgba(255, 255, 255, 0.5);

    /* Default to light theme */
    --background: var(--background-light);
    --background-gradient: var(--background-light-gradient);
    --scrollbar: var(--scrollbar-light);
    --scrollbar-hover: var(--scrollbar-hover-light);
}

@media (prefers-color-scheme: dark) {
    :root {
        --background: var(--background-dark);
        --scrollbar: var(--scrollbar-dark);
        --scrollbar-hover: var(--scrollbar-hover-dark);
    }
}

@media (prefers-color-scheme: light) {
    :root {
        --background: var(--background-light);
        --scrollbar: var(--scrollbar-light);
        --scrollbar-hover: var(--scrollbar-hover-light);
    }
}

/* Dark theme */
[data-theme="dark"] {
    --background: var(--background-dark);
    --scrollbar: var(--scrollbar-dark);
    --scrollbar-hover: var(--scrollbar-hover-dark);
}

/* Light theme */
[data-theme="light"] {
    --background: var(--background-light);
    --scrollbar: var(--scrollbar-light);
    --scrollbar-hover: var(--scrollbar-hover-light);
}

:root {
    /* background: var(--background); */
    background: var(--background);
}

body {
    /* prevent jumping scrolling */
    overflow-y: scroll;
    background-attachment: fixed;

    box-sizing: border-box;
    overflow-x: hidden;
    margin: 0;
    font-family: "ABC Diatype Unlicensed Trial", Helvetica Neue, Helvetica, -apple-system, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-hover);
    border: 3px solid transparent;
    background-clip: content-box;
}

/* Prevent +/- arrows on inputs */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
